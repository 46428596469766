// auth.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const configureAuth = () => {
    const config = {
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
      oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN || 'pulsestudio.auth.us-east-1.amazoncognito.com',
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: 'code'
      }
    };
  
    console.log('Auth Configuration:', config);
    Auth.configure(config);
  };

  useEffect(() => {
    configureAuth();

    // Handle URL parameters on load
    const params = new URLSearchParams(window.location.search);
    if (params.has('code')) {
      console.log('Found authorization code in URL');
    } else if (params.has('error')) {
      console.error('Auth Error:', {
        error: params.get('error'),
        description: params.get('error_description')
      });
    }

    // Check current auth state
    Auth.currentAuthenticatedUser()
      .then(user => {
        console.log('Current user:', user);
        setUser(user);
      })
      .catch(() => {
        console.log('No current user');
        setUser(null);
      })
      .finally(() => {
        setLoading(false);
      });

    // Listen for auth events
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log('Auth event:', event);
      switch (event) {
        case 'signIn':
          setUser(data);
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'customOAuthState':
        case 'tokenRefresh':
          console.log('Auth state:', event, data);
          break;
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const signIn = async () => {
    try {
      console.log('Starting sign in process');
      await Auth.federatedSignIn({
        provider: 'Google',
      });
    } catch (error) {
      console.error('Sign in error:', error);
    }
  };

  const signOut = async () => {
    try {
      console.log('Starting sign out');
      // Use global signOut to properly handle federated sign-out
      await Auth.signOut({ 
        global: true 
      });
      setUser(null);
    } catch (error) {
      console.error('Sign out error:', error);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-white text-xl">Loading...</div>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};